import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { getRandomInt } from "./helpers";

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".feedback path").forEach(function (elem) {
  ScrollTrigger.create({
    trigger: elem,
    start: "top 90%",
    once: true,
    onEnter: function () {
      elem.style.opacity = "0";
      elem.a = 0;

      gsap.timeline({ onUpdate: () => {
        elem.style.filter = `blur(${elem.a}px)`
        elem.style.webkitFilter = `blur(${elem.a}px)`
      } }).from(elem, {
        autoAlpha: 0,
        fill: 'transparent',
        a: 0,
      }).to(elem, {
        duration: 0.2,
        delay: getRandomInt(1, 3) / 10,
        ease: "linear",
        autoAlpha: 1,
        a: 5,
      }).to(elem, {
        duration: 1.5,
        delay: getRandomInt(1, 5) / 10,
        ease: "linear",
        fill: '#272C38',
        a: 0,
      })
    },
  });
});

gsap.utils.toArray(".feedback span").forEach(function (elem) {
  ScrollTrigger.create({
    trigger: elem,
    start: "top 90%",
    once: true,
    onEnter: function () {
      elem.style.opacity = "0";
      gsap.timeline({}).from(elem, {
        autoAlpha: 0,
      }).to(elem, {
        duration: 0.5,
        delay: getRandomInt(1, 3) / 10,
        ease: "linear",
        autoAlpha: 1,
      }).to(elem, {
        duration: 0.5,
        delay: getRandomInt(1, 3) / 10,
        ease: "linear",
        '-webkit-text-fill-color': '#272C38',
      })
    },
  });
});